'use strict';

// Constructor
var Header = function() {
    var header = $('.header');
    var body = $('body');
    var menuOpen = $('.header__hamburguer');
    console.log(window.location.href);

    if (window.location.pathname === '/') {
      body.scrollTop(0);
    }

    menuOpen.on('click', function(){
        header.toggleClass('-open');
        body.toggleClass('-hideOverflow');
    });

    $('.home__pre__main').on('click', function() {
      body.removeClass('-hideOverflow');
      $('.home__pre').removeClass('-open');
      localStorage.setItem('cuestaDelMaderoVisited', true);
    });

    if (!localStorage.getItem('cuestaDelMaderoVisited')) {
      body.addClass('-hideOverflow');
      $('.home__pre').addClass('-open');
    }



    // var viewport = 0;
    // var scroll = 0;

    // if($(window).width() < 640) {
    //     viewport = 65;
    //     scroll = 100;
    // } else {
    //     viewport = 70;
    //     scroll = 400;
    // }

    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: target.offset().top - 65
                }, 1000, function() {
                // Callback after animation
                // Must change focus!
                    var $target = $(target);
                    $target.focus();
                });
            }
        }
    });
};

module.exports = Header;
